import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { zIndex } from './vars/zIndex.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { mq } from './vars/media-queries.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  :root {
    --reach-tabs: 1; // This disables the Reach UI warning about not including matching CSS files https://reach.tech/styling/
    --reach-dialog: 1;
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    &.disable-scroll {
      overflow: hidden;
    }
  }

  [data-reach-dialog-overlay] {
    z-index: ${zIndex.modal};
  }

  img {
    height: auto;
    width: 100%;
  }

  s {
    display: inline-block;
    position: relative;
    text-decoration: none;
  }

  s::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: .2em;
    transform: translateY(-50%);
    width: 100%;
    background: ${colors.orange};
  }

  // SimpleBar

  [data-simplebar] {
    height: 100%;
  }

  .simplebar-track {
    background: ${colors.lightgrey} !important;
    border-radius: 2px !important;
    bottom: 30px !important;
    right: 10px !important;
    top: 30px !important;

    ${mq.tabletL} {
      bottom: 68px !important;
      right: 24px !important;
      top: 68px !important;
    }
  }

  .simplebar-track.simplebar-vertical {
    width: 2px !important;
  }

  .simplebar-scrollbar:before {
    background: ${colors.orange} !important;
    opacity: 1 !important;
    right: 0 !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
  }
`
